export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Hey, I'm Eyob</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Digital</span>{" "}
            <br />
            Marketer
          </h1>
          <p className="hero--section-description">
            This is My Blogging website
            <br /> 
          </p>
        </div>
         
        <a href="https://linktr.ee/eyobsahele" target="_blank" className="btn btn-primary">My linktree</a>
       
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.png" alt="Hero Section" />
      </div>
    </section>
  );
}
