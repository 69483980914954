export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">About</p>
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
            Eyob Sahle's achievements and experiences are a testament to his dedication to personal and professional growth, his commitment to making a positive impact in his community, and his leadership skills. His work as a Stock Agent at Purpose Black Ethiopia Share Company, his participation in various training programs, his support of charitable causes, and his innovative thinking in digital marketing all showcase his impressive capabilities. Overall, Eyob Sahle is a remarkable individual who has much to offer in both his personal and professional life.
          </p>
          <p className="hero--section-description">

          </p>
        </div>
      </div>
    </section>
  );
}
